import { DatePipe } from "@angular/common";
import { Inject, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouterModule, UrlSerializer } from "@angular/router";

import { Angulartics2, Angulartics2Module } from "angulartics2";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { IconSharedModule } from "pf_styleguide";

import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import { APP_ROUTES } from "./app.routes";
import { ConfigureModule } from "./configure/configure.module";
import { ConfigureService } from "./configure/configure.service";
import { DiscontinuedSportModule } from "./discontinued-sport/discontinued-sport.module";
import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";
import { NotFoundModule } from "./not-found/not-found.module";
import { LinkService } from "./shared/link.service";
import { AuthModule } from "./shared/module/auth/auth.module";
import { CookiePolicyModule } from "./shared/module/cookie-policy/cookie.policy.module";
import { FeaturedModule } from "./shared/module/featured/featured.module";
import { WindowRefService } from "./shared/window.service";
import { LowerCaseUrlSerializer } from "./url.serializer";
import { CityModule } from "./city/city.module";
import { CitySelectService } from "./shared/city-select.service";
import {PrebootModule} from "preboot";

export { AppComponent };

const excludedRoutes = [
    /^\/404$/,
    /^\/[a-zA-Z]+\/cycling\/404$/,
    /^\/[a-zA-Z]+\/rowing\/404$/,
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        QuicklinkModule,
        ConfigureModule.forRoot(),
        RouterModule.forRoot(APP_ROUTES, {
            initialNavigation: "enabled",
            preloadingStrategy: QuicklinkStrategy,
        }),
        ReactiveFormsModule,
        Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
        IconSharedModule,
        FooterModule,
        CookiePolicyModule,
        DiscontinuedSportModule,
        NotFoundModule,
        AuthModule,
        HeaderModule,
        FeaturedModule,
        CityModule,
        BrowserModule.withServerTransition({ appId: 'playfinder' }),
        PrebootModule.withConfig({ appRoot: 'app-root' })
    ],
    exports: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: "en-GB" },
        DatePipe,
        WindowRefService,
        LinkService,
        ConfigureService,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
        CitySelectService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        angulartics2: Angulartics2,
    ) {
        angulartics2.settings.pageTracking.excludedRoutes = excludedRoutes;
    }
}
